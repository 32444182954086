<template>
  <div>
    <slot></slot>
    <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNo"
            :page-sizes="[10, 20, 50, 100]"
            layout="total, prev, pager, next,sizes, jumper"
            :hide-on-single-page="false"
            :page-size="pageSize"
            :total="total">
    </el-pagination>
  </div>
</template>

<script>
  export default {
    name: "reconovaPagination",
    props: {
      pageNo:{
        type:Number,
        default(){return 1}
      },
      pageSize:{
        type:Number,
        default(){return 20}
      },
      total:{
        type:Number,
        default(){return 0}
      }
    },
    data() {
      return {
      }
    },
    methods: {
      handleSizeChange(val){
        this.$emit("paginationChange",'pageSize',val)
      },
      handleCurrentChange(val){
        this.$emit("paginationChange",'pageNo',val)
      }
    }
  }
</script>

<style scoped>
  .tree_search {
    padding: 0 8px;
    width: 100%;
    margin-bottom: 8px;
  }

  .tree {
    font-size: 12px;
  }

  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 12px;
  }

  .custom-tree-node img {
    display: none;
    width: 16px;
    height: 16px;
    margin: 0 5px;
  }

  .custom-tree-node img:hover {
    position: relative;
    bottom: 2px;
  }

  .custom-tree-node:hover img {
    display: inline-block;
    vertical-align: middle;
  }
</style>
