<template>
  <div class="list">
    <search-box @change="research" @export="_exportForm"></search-box>
    <div class="contentBox">
      <div class="contentTool">
        <el-button @click="add" type="primary" size="medium">新增</el-button>
      </div>
      <el-table
        class="contentTable"
        :data="tableData"
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="jobNumber" label="工号">
          <template slot-scope="scope">
            {{ scope.row.jobNumber || "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="staffName" label="姓名">
          <template slot-scope="scope">
            {{ scope.row.staffName || "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="businessName" label="部门">
          <template slot-scope="scope">
            {{ scope.row.sysDeptName ? scope.row.sysDeptName.deptName : "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="creatorName" label="岗位">
          <template slot-scope="scope" v-if="scope.row.sysPositionNameList && scope.row.sysPositionNameList.length">
            {{
              scope.row.sysPositionNameList.map((e) => e.positionName).join(",")
            }}
          </template>
        </el-table-column>
        <el-table-column prop="entryTime" label="入职时间">
          <template slot-scope="scope">
            {{ scope.row.entryTime || "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="手机号"> </el-table-column>
        <el-table-column prop="status" label="审核状态">
          <template slot-scope="scope">
            {{ $reconava.getStatusName(scope.row.status) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="createDate"
          label="操作"
          class-name="operation"
          width="150"
        >
          <template slot-scope="scope">
            <span @click="see(scope.row)" class="blue">查看</span>
            <span
              @click="edit(scope.row)"
              class="blue"
              v-if="
                (scope.row.status === 6 || scope.row.status === 3) &&
                $reconava.isCreatedByCurrentUser(scope.row.createBySysUserName)
              "
              >编辑</span
            >
            <span
              @click="stop(scope.row)"
              class="blue"
              v-if="
                scope.row.status === 3 &&
                $reconava.isCreatedByCurrentUser(scope.row.createBySysUserName)
              "
              >终止</span
            >
            <span
              @click="revoke(scope.row)"
              class="blue"
              v-if="[102].includes(scope.row.status)"
              >撤销</span
            >
            <!--     审批通过后，可以打印详情           -->
            <span class="mgr10 blue" v-if="scope.row.status===4" @click="_print(scope.row.id)">打印</span>
          </template>
        </el-table-column>
      </el-table>
      <ReconovaPagination
        v-if="tableData.length > 0"
        @paginationChange="paginationChange"
        :page-no="pageNum"
        :page-size="pageSize"
        :total="total"
      >
      </ReconovaPagination>
    </div>
  </div>
</template>

<script>
import SearchBox from "./components/searchBox";
import ReconovaPagination from "@/components/reconova/reconovaPagination";
import { getCheckList } from "@/services/check";
import { operatorDialog } from "@/utils/utils";
import {onDownload} from "@/utils/utils";
export default {
  name: "list",
  components: {
    ReconovaPagination,
    SearchBox,
  },
  data() {
    return {
      currentId: "",
      pageNum: 1,
      pageSize: 10,
      search: {},
      tableData: [],
      total: 1,
      ids: [],
    };
  },
  methods: {
    stop(row) {
      operatorDialog(this, "理由", "撤销", (value) => {
        let param = {
          id: row.id,
          typeId: 6,
          reason: value || "",
        };
        this.$api.checkapi.terminateMyApply(param).then((response) => {
          this.$message.success("已终止");
          this.getData();
        });
      });
    },
    revoke(item) {
      operatorDialog(this,'理由','撤销',(value)=>{
        let param = {
          id:item.id,
          typeId:6,
          reason:value||''
        }
        this.$api.checkapi.repealMyApply(param).then(response=>{
          this.$message.success('已撤销');
          this.getData()
        })
      })
    },
    submit() {
      this.$api.personnel
        .approvalPersonOperationSubmit({
          ids: this.ids,
        })
        .then((res) => {
          this.$message.success("申请已提交");
          this.getData();
          this.ids = [];
        });
    },
    add() {
      this.$emit("changeType", {
        type: "add",
      });
    },
    see(row) {
      //查看
      this.$emit("changeType", {
        type: "detail",
        id: row.id + "",
      });
    },
    edit(row) {
      this.$emit("changeType", {
        type: "add",
        id: row.id + "",
      });
    },
    handleSelectionChange(data) {
      this.ids = data.map((e) => e.id);
    },
    selectAble(row) {
      //table行是否可被选
      return true;
      // return row.status === 0;
    },
    paginationChange(type, val) {
      //翻页
      if (type === "pageNo") {
        this.pageNum = val;
      } else {
        this.pageNum = 1;
        this.pageSize = val;
      }
      this.getData();
    },
    research(data, isreset) {
      this.pageNum = 1;
      this.pageSize = isreset ? 10 : this.pageSize;
      this.search = Object.assign(this.search, data);
      this.getData();
    },
    //获取列表数据
    getData() {
      this.$api.personnel
        .approvalPersonOperationList({
          ...this.search,
          pageSize: this.pageSize,
          pageNum: this.pageNum,
        })
        .then((res) => {
          this.tableData = res.data.list;
          this.total = res.data.total;
        });
    },

    _exportForm(data){
      this.$api.personnel.approvalPersonExport(Object.assign({pageSize:10000,pageNum:1},data)).then(({headers,data})=>{
        onDownload(headers,data)
      })
    },
    _print(id){
      this.$router.push({path:"/businessModule/personnel/entryFlow/print",query:{id}})
    }
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss">
.list {
  display: flex;
  flex-direction: column;
  height: 100%;
  .contentBox {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    .el-table {
      overflow-y: auto;
      flex: 1;
    }
  }
  .operation {
    span {
      margin-right: 8px;
    }
  }
}
</style>