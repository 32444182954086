<template>
  <div class="searchBox search-box">
    <div class="searchForm">
      <div class="item searchItem">
        <span>姓名：</span>
        <el-input v-model="staffName" size="medium"></el-input>
      </div>
      <div class="item searchItem">
        <span style="margin-left: 16px">手机号：</span>
        <el-input v-model="mobile" size="medium"></el-input>
      </div>
      <div class="item searchItem">
        <span>状态：</span>
        <el-select v-model="status" placeholder="请选择" size="medium" clearable>
          <el-option
            :label="e.label"
            :value="e.value"
            v-for="(e, i) in $map.getter('checkStatus').filter(e => [1, 2, 3, 4, 7].includes(+e.value))"
            :key="i"
          ></el-option>
        </el-select>
      </div>
      <div class="item searchItem">
        <el-button
          @click="search"
          type="primary"
          style="margin-left: 16px"
          size="medium"
          >查询
        </el-button>
        <el-button @click="_export" type="primary" size="medium">导出</el-button>
        <el-button @click="reset()" style="margin-left: 16px" size="medium"
          >重置
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "searchBox",
  data() {
    return {
      status: "",
      mobile: "",
      staffName: "",
    };
  },
  methods: {
    search() {
      this.$emit("change", this.data);
    },
    reset() {
      this.staffName = "";
      this.mobile = "";
      this.status = "";
      this.$emit("change", this.data);
    },
    _export(){
      this.$emit('export',this.data)
    }
  },
  computed: {
    data() {
      return {
        staffName: this.staffName,
        mobile: this.mobile,
        status: this.status,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  .item {
    display: inline-flex;
    align-items: center;
    margin-right: 8px;
    span {
      white-space: nowrap;
    }
  }
}
</style>
