<template>
  <div class="entry-flow">
    <template v-if="viewType === 'list'">
      <!-- 列表 -->
      <list @changeType="changeType"></list>
    </template>
    <template v-if="viewType === 'detail'">
      <!-- 详情 -->
      <detail @changeType="changeType" :id="currentId"></detail>
    </template>
    <template v-if="viewType === 'add'">
      <!-- 详情 -->
      <add @changeType="changeType" :id="currentId"></add>
    </template>
  </div>
</template>

<script>
import List from "./list";
import Detail from "./detail";
import Add from "./add";
export default {
  name: "EntryFlow",
  components: { Detail, List, Add },
  data() {
    return {
      currentId: "",
      viewType: "list", //list列表 & detail详情
    };
  },
  methods: {
    changeType(data) {
      this.viewType = data.type;
      this.currentId = data.id;
    },
  },
  created() {
    let viewType = this.$route.query.type;
    viewType && (this.viewType = viewType)
    this.currentId = this.$route.query.id;
  },
};
</script>

<style lang="scss" scoped>
.entry-flow {
  // background-color: white;
}
</style>